import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface FAQItem {
  question: string;
  answer: string;
}

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { t } = useTranslation("components/faq");

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";

    const faqItems = Array.from({ length: 8 }, (_, index) => ({
      "@type": "Question",
      name: t(`items.${index}.question`),
      acceptedAnswer: {
        "@type": "Answer",
        text: t(`items.${index}.answer`),
      },
    }));

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: faqItems,
    };

    script.text = JSON.stringify(schemaData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [t]);

  const toggleQuestion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section
      className="bg-lighter dark:bg-darker text-gray-800 dark:text-gray-300 py-12"
      aria-label="Frequently Asked Questions"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-center mb-8 text-gray-900 dark:text-white">
          {t("title")}
        </h1>

        {/* Container for all FAQ items */}
        <div className="space-y-4">
          {Array.from({ length: 8 }, (_, index) => (
            <article
              key={index}
              className="faq-item border-b border-gray-200 dark:border-gray-700 pb-4"
              aria-labelledby={`faq-question-${index}`}
            >
              {/* Question Header */}
              <div className="faq-question">
                <h2 className="mb-0">
                  <button
                    id={`faq-question-${index}`}
                    className="flex justify-between items-center w-full text-left"
                    onClick={() => toggleQuestion(index)}
                    aria-expanded={activeIndex === index}
                    aria-controls={`faq-answer-${index}`}
                  >
                    <span className="text-lg font-medium text-gray-900 dark:text-white">
                      {t(`items.${index}.question`)}
                    </span>
                    <svg
                      className={`w-5 h-5 text-gray-500 transform ${
                        activeIndex === index ? "rotate-180" : ""
                      } transition-transform duration-200`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                </h2>
              </div>

              {/* Answer Content */}
              {activeIndex === index && (
                <div
                  id={`faq-answer-${index}`}
                  className="faq-answer mt-2 text-gray-600 dark:text-gray-300"
                  role="region"
                  aria-labelledby={`faq-question-${index}`}
                >
                  {t(`items.${index}.answer`)}
                </div>
              )}
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
