import React from "react";

export interface Resource {
  id: string;
  translationKey: string; // Key to look up translations
}

export const resources: Resource[] = [
  {
    id: "trimap",
    translationKey: "trimap",
  },
  {
    id: "creating-alpha-matting-dataset",
    translationKey: "datasetCreation",
  },
  {
    id: "withoutbg100-image-matting-dataset",
    translationKey: "withoutbg100",
  },
  {
    id: "creating-sticker",
    translationKey: "sticker",
  },
  {
    id: "adding-drop-shadow",
    translationKey: "dropShadow",
  },
  {
    id: "aws-nova-canvas-background-removal",
    translationKey: "awsNovaCanvas",
  },
];

interface ResourcesContextType {
  resources: Resource[];
}

export const ResourcesContext = React.createContext<ResourcesContextType>({
  resources,
});

export const useResources = () => React.useContext(ResourcesContext);
