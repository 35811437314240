import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner";
import NotFound from "./NotFound";
import Navbar from "../components/Navbar";
import SecondaryNavbar from "../components/SecondaryNavbar";
import Footer from "../components/Footer";
import { ThemeProvider } from "../contexts/ThemeContext";
import { AuthProvider } from "../contexts/AuthContext/AuthContext";
import { SecondaryNavbarProvider } from "../components/SecondaryNavbarContext";
import LoginPopover from "../components/Auth/LoginPopover";
import { LanguageRoute } from "../components/LanguageRoute";
import { isValidLanguage } from "../utils/languageUtils";
import i18n from "../config/i18n";
import { useAuthState } from "../hooks/useAuthState";
import { useRoutes } from "./routeConfig";

const MainRoutes: React.FC = () => {
  const { routes } = useRoutes();

  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const RouteWrapper: React.FC = () => {
  const location = useLocation();
  const { validPaths } = useRoutes();
  const segments = location.pathname.split("/").filter(Boolean);
  const firstSegment = segments[0];

  const isValidPath = (path: string) => {
    // Remove language prefix if it exists
    const pathWithoutLang = isValidLanguage(firstSegment)
      ? "/" + segments.slice(1).join("/")
      : location.pathname;

    // Check if the path (or its parent) is in validPaths
    return validPaths.some((validPath) => {
      if (validPath === "") {
        return pathWithoutLang === "/";
      }

      // Special handling for payment success route
      if (pathWithoutLang.startsWith("/payment/success/")) {
        return true; // Allow any payment success route
      }

      return pathWithoutLang.startsWith("/" + validPath);
    });
  };

  return isValidPath(location.pathname) ? <MainRoutes /> : <NotFound />;
};

const AuthenticatedApp: React.FC = () => {
  const { isLoginOpen, closeLogin, openLogin, handleAuthAction } =
    useAuthState();

  return (
    <div className="flex flex-col min-h-screen transition-colors duration-200 bg-light dark:bg-gray-900">
      <Navbar onLoginClick={openLogin} />
      <SecondaryNavbar />
      <main className="flex-grow">
        <LanguageRoute>
          <RouteWrapper />
        </LanguageRoute>
      </main>
      <Footer />
      <LoginPopover
        isOpen={isLoginOpen}
        onClose={closeLogin}
        onSubmit={(email) => handleAuthAction(email, false)}
      />
    </div>
  );
};

const Home: React.FC = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <HelmetProvider>
        <I18nextProvider i18n={i18n}>
          <Router>
            <ThemeProvider>
              <AuthProvider>
                <SecondaryNavbarProvider>
                  <AuthenticatedApp />
                </SecondaryNavbarProvider>
              </AuthProvider>
            </ThemeProvider>
          </Router>
        </I18nextProvider>
      </HelmetProvider>
    </Suspense>
  );
};

export default Home;
