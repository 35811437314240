import React from "react";
import SEOHead from "../../components/SEOHead";

const Imprint: React.FC = () => {
  return (
    <>
      <SEOHead
        title={"Imprint"}
        description={"Imprint - withoutbg.com"}
        imageUrl="https://withoutbg.com/withoutbg-og.png"
      />

      <div className="w-full bg-light dark:bg-dark text-gray-800 dark:text-gray-300 pt-40">
        <div className="max-w-7xl mx-auto pt-8 pb-12 px-4 sm:px-6 lg:px-8">
          <div className="container">
            <h2 className="font-bold mt-3 mb-1">
              This website is operated by:
            </h2>
            <p>withoutbg API</p>
            <p>Founder / Manager: Imran Kocabiyik</p>

            <p>
              Registered at the <b>Finanzamt Charlottenburg</b> in Berlin
            </p>
            <p>Tax Number: 13/388/02353</p>

            <h3 className="font-bold mt-3 mt-1">Address</h3>
            <p>Guerickestr. 36 10587 Berlin</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Imprint;
