import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSecondaryNavbar } from "../../components/SecondaryNavbarContext";
import SEOHead from "../../components/SEOHead";
import SimpleCodeWindow from "../../components/Documentation/SimpleCodeWindow";
import BackToTop from "../../components/Resources/BackToTop";

interface StepImage {
  url?: string;
  alt?: string;
}

interface Step {
  title: string;
  content: string;
  image?: StepImage;
}

interface CreateStickerTranslations {
  meta: {
    title: string;
    description: string;
  };
  main_title: string;
  intro: {
    content: string;
  };
  prerequisites: {
    title: string;
    content: string;
  };
  steps: {
    title: string;
    items: Step[];
  };
}

const CreateSticker: React.FC = () => {
  const { setContent } = useSecondaryNavbar();
  const { t } = useTranslation("resources/sticker");
  const [pythonCodes, setPythonCodes] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    // Load all Python files
    const fetchPythonCodes = async () => {
      try {
        const codes: { [key: number]: string } = {};
        for (let i = 1; i <= 7; i++) {
          const response = await fetch(`/resources/sticker/step${i}.py`);
          if (!response.ok) {
            throw new Error(`Failed to load Python code for step ${i}`);
          }
          const code = await response.text();
          codes[i] = code;
        }
        setPythonCodes(codes);
      } catch (error) {
        console.error("Error loading Python codes:", error);
      }
    };

    fetchPythonCodes();
  }, []);

  // Get the steps array and cast it to the correct type
  const stepsItems = t("steps.items", { returnObjects: true });
  const stepsData = Array.isArray(stepsItems) ? (stepsItems as Step[]) : [];

  return (
    <>
      <SEOHead
        title={t("meta.title")}
        description={t("meta.description")}
        imageUrl="https://withoutbg.com/create-sticker-documentation-og.png"
      />

      <div className="min-h-screen bg-white dark:bg-gray-900">
        <main className="max-w-4xl mx-auto px-4 py-12">
          {/* Main Title */}
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8 mt-10">
            {t("main_title")}
          </h1>

          {/* Introduction */}
          <section className="mb-12">
            <p className="text-lg text-gray-700 dark:text-gray-300">
              {t("intro.content")}
            </p>
          </section>

          {/* Prerequisites */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("prerequisites.title")}
            </h2>
            <div className="prose dark:prose-invert max-w-none">
              <p className="text-gray-700 dark:text-gray-300 whitespace-pre-line">
                {t("prerequisites.content")}
              </p>
            </div>
          </section>

          {/* Steps */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("steps.title")}
            </h2>
            {stepsData.map((step: Step, index: number) => (
              <div key={index} className="mb-8">
                <h3 className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-4">
                  {step.title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                  {step.content}
                </p>
                {pythonCodes[index + 1] && (
                  <SimpleCodeWindow
                    content={pythonCodes[index + 1]}
                    language="python"
                    title={step.title}
                    showLineNumbers={true}
                    wrapLongLines={true}
                    className="my-4"
                  />
                )}
                {step.image && step.image.url && (
                  <div className="rounded-lg overflow-hidden shadow-lg border border-gray-200 dark:border-gray-700">
                    <img
                      src={step.image.url}
                      alt={step.image.alt || step.title}
                      className="w-full h-auto"
                      loading="lazy"
                    />
                  </div>
                )}
              </div>
            ))}
          </section>
        </main>

        <BackToTop />
      </div>
    </>
  );
};

export default CreateSticker;
