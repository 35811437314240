import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { resources, ResourcesContext } from "./ResourcesContext";
import SEOHead from "../../components/SEOHead";
import { useLocalizedNavigation } from "../../hooks/useLocalizedNavigation";

const ResourceIndex: React.FC = () => {
  // Load the main index translations
  const { t: indexT } = useTranslation("resources/index");
  const { createLocalizedLink } = useLocalizedNavigation();

  // Load all resource translations
  const { t } = useTranslation([
    "resources/trimap",
    "resources/datasetCreation",
    "resources/dropShadow",
    "resources/sticker",
    "resources/withoutbg100",
    "resources/awsNovaCanvas",
  ]);

  return (
    <ResourcesContext.Provider value={{ resources }}>
      <SEOHead
        title={indexT("meta.title")}
        description={indexT("meta.description")}
        imageUrl="https://withoutbg.com/withoutbg-og.png"
      />
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-gray-100">
          {indexT("heading")}
        </h1>
        <div className="space-y-8">
          {resources.map((resource) => {
            // Get the translation namespace for this resource
            const ns = `resources/${resource.translationKey}`;

            return (
              <div
                key={resource.id}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all hover:shadow-lg"
              >
                <Link
                  to={createLocalizedLink(`/resources/${resource.id}`)}
                  className="block"
                >
                  <h2 className="text-xl font-semibold mb-2 text-purple-600 dark:text-purple-400">
                    {t(`${ns}:meta.title`)}
                  </h2>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">
                    {t(`${ns}:meta.description`)}
                  </p>
                  <div className="flex items-center justify-between">
                    <span className="text-purple-600 dark:text-purple-400 font-medium">
                      {indexT("readMore")}
                    </span>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </ResourcesContext.Provider>
  );
};

export default ResourceIndex;
