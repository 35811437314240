import React from "react";
import { Helmet } from "react-helmet";
import BeforeAfterSlider from "../components/Gallery/BeforeAfterSlider";
import SEOHead from "../components/SEOHead";

import Masonry from "react-masonry-css";

interface PhotoData {
  photographer: string;
  photographerURL: string;
  photoURL: string;
  platform: string;
  fileName: string;
  altText: string;
  assessment: string;
  aspectRatio?: number;
}

const ExamplesGallery: React.FC = () => {
  const canonicalUrl = "https://withoutbg.com/";
  const mediaUrl = "https://media.withoutbg.com/examples";

  const photoData: PhotoData[] = [
    {
      photographer: "Diego Rosa",
      photographerURL: "https://unsplash.com/@dihrosa",
      photoURL: "https://unsplash.com/photos/Yyk4OmVJAyE",
      platform: "Unsplash",
      fileName: "woman-in-golden-hour",
      altText: "Woman in Golden Hour",
      assessment: "Very Good",
      aspectRatio: 4 / 5,
    },
    {
      photographer: "Annie Spratt",
      photographerURL: "https://unsplash.com/@anniespratt",
      photoURL: "https://unsplash.com/photos/y0oqXbtNYsE",
      platform: "Unsplash",
      fileName: "woman-with-flowers",
      altText: "Woman Carrying Flowers",
      assessment: "Good. Some fine details are not cut well.",
      aspectRatio: 3 / 5,
    },
    {
      // knitting
      photographer: "Rebecca Grant",
      photographerURL: "https://unsplash.com/@luandesign",
      photoURL: "https://unsplash.com/photos/of_fQz_moyA",
      platform: "Unsplash",
      fileName: "hands",
      altText: "Hands Knitting",
      assessment: "",
      aspectRatio: 5 / 3,
    },
    // dandelion
    {
      photographer: "Kristaps Ungurs",
      photographerURL: "https://unsplash.com/@kristapsungurs",
      photoURL: "https://unsplash.com/photos/nSpgmpsAsAE",
      platform: "Unsplash",
      fileName: "dandelion",
      altText: "Dandelion",
      assessment: "",
      aspectRatio: 3 / 5,
    },

    //  cat
    {
      photographer: "Erik-Jan Leusink",
      photographerURL: "https://unsplash.com/@ejleusink",
      photoURL: "https://unsplash.com/photos/IbPxGLgJiMI",
      platform: "Unsplash",
      fileName: "cat",
      altText: "Cat",
      assessment: "Very Good",
      aspectRatio: 1,
    },

    //  ice cream
    {
      photographer: "Cadenc _moving",
      photographerURL: "https://unsplash.com/@cadence_26",
      photoURL: "https://unsplash.com/photos/5KKldJx02xo",
      platform: "Unsplash",
      fileName: "ice-cream",
      altText: "Hand Holding Ice Cream",
      assessment: "Very Good",
    },

    //  flowers
    {
      photographer: "Edward Howell",
      photographerURL: "https://unsplash.com/@edwardhowellphotography",
      photoURL: "https://unsplash.com/photos/ywk-7XpYZus",
      platform: "Unsplash",
      fileName: "flowers",
      altText: "Flowers",
      assessment: "Very Good",
    },

    //  tools
    {
      photographer: "hosein zanbori",
      photographerURL: "https://unsplash.com/@hoseincameraman",
      photoURL: "https://unsplash.com/photos/G4ekirU5aFQ",
      platform: "Unsplash",
      fileName: "tools",
      altText: "Tools on the Ground",
      assessment: "Good. Pins are not cut well.",
    },

    //  hammer
    {
      photographer: "iMattSmart",
      photographerURL: "https://unsplash.com/@imattsmart",
      photoURL: "https://unsplash.com/photos/6InBHpsK2PE",
      platform: "Unsplash",
      fileName: "hammer",
      altText: "Hammer on a Wooden Table",
      assessment: "Very Good",
    },

    {
      photographer: "Markus Spiske",
      photographerURL: "https://unsplash.com/@markusspiske",
      photoURL: "https://unsplash.com/photos/CkjvR87_NYg",
      platform: "Unsplash",
      fileName: "basket-on-wall",
      altText: "Basket on Wall",
      assessment: "Very Good",
    },

    //  hand holding plant
    {
      photographer: "Alex Lvrs",
      photographerURL: "https://unsplash.com/@alexlvrs",
      photoURL: "https://unsplash.com/photos/4N5huJDOydQ",
      platform: "Unsplash",
      fileName: "hand-holding-plant",
      altText: "Hand Holding a Plant",
      assessment: "Good. One of the leaves is not cut well.",
    },

    //  ferris wheel
    {
      photographer: "Conor Luddy",
      photographerURL: "https://unsplash.com/@opticonor",
      photoURL: "https://unsplash.com/photos/ddVk1aQrTWA",
      platform: "Unsplash",
      fileName: "ferris-wheel",
      altText: "Ferris Wheel in Motion",
      assessment:
        "Good. Even if the photo is challenging, the result is tolerable.",
    },
    {
      photographer: "Joshua Newton",
      photographerURL: "https://unsplash.com/@joshuanewton",
      photoURL: "https://unsplash.com/photos/7qjqQjt7zXQ",
      platform: "Unsplash",
      fileName: "flames",
      altText: "Flames",
      assessment: "Good",
    },

    {
      photographer: "Joshua Hoehne",
      photographerURL: "https://unsplash.com/@mrthetrain",
      photoURL: "https://unsplash.com/photos/7XLsFy0p3Ao",
      platform: "Unsplash",
      fileName: "basket",
      altText: "Basket in Sky",
      assessment: "Very Good",
      aspectRatio: 16 / 9,
    },
    {
      photographer: "Nik",
      photographerURL: "https://unsplash.com/@helloimnik",
      photoURL: "https://unsplash.com/photos/91UEmzLi-_Y",
      platform: "Unsplash",
      fileName: "knitting",
      altText: "Knitting",
      assessment: "Good. Some background details are visible.",
    },
    {
      photographer: "Michael Oeser",
      photographerURL: "https://unsplash.com/@michaeloeser",
      photoURL: "https://unsplash.com/photos/nfj1gzws7HM",
      platform: "Unsplash",
      fileName: "glass",
      altText: "Glass",
      assessment: "Poor. Transparency is not handled well.",
    },
    {
      photographer: "Prudence Earl",
      photographerURL: "https://unsplash.com/@prudenceearl",
      photoURL: "https://unsplash.com/photos/NwBx723XaHw",
      platform: "Unsplash",
      fileName: "plants-in-room",
      altText: "Plants in Room",
      assessment: "OK. Background pixels visible",
    },
    {
      photographer: "Ilya Shishikhin",
      photographerURL: "https://unsplash.com/@shishilya",
      photoURL: "https://unsplash.com/photos/aO5AMSdGHPo",
      platform: "Unsplash",
      fileName: "flying-hairs",
      altText: "Flying Hairs",
      assessment: "OK. Fine details are not cut well.",
    },
    {
      photographer: "Ron McClenny",
      photographerURL: "https://unsplash.com/@ronmcclenny",
      photoURL: "https://unsplash.com/photos/9yI8eQ9mdvY",
      platform: "Unsplash",
      fileName: "flowers-and-chair-in-room",
      altText: "Flowers and Chair in Room",
      assessment: "OK. The foreground objects are not clear.",
    },
    {
      photographer: "Dimon Blr",
      photographerURL: "https://unsplash.com/@dimonblr",
      photoURL: "https://unsplash.com/photos/mdgMbYfFlSA",
      platform: "Unsplash",
      fileName: "bikes",
      altText: "Bike Racing",
      assessment: "OK. Input image is challenging.",
    },
    // Add more photo data here
  ];

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <>
      <SEOHead
        title={"Examples"}
        description={
          "Explore our before-and-after gallery. See real transformations and get inspired for your own projects."
        }
        imageUrl="https://withoutbg.com/withoutbg-og.png"
      />
      <div className="container mx-auto px-4 py-8 pt-40">
        <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
          Example Gallery
        </h2>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="flex w-auto -ml-4"
          columnClassName="pl-4 bg-clip-padding"
        >
          {photoData.map((photo, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden mb-4"
            >
              <BeforeAfterSlider
                before={`${mediaUrl}/${photo.fileName}.jpg`}
                after={`${mediaUrl}/${photo.fileName}.png`}
                alt={photo.altText}
                aspectRatio={photo.aspectRatio || 1}
              />
              <div className="p-4">
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
                  Photo by{" "}
                  <a
                    href={photo.photographerURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 dark:text-blue-400 hover:underline"
                  >
                    {photo.photographer}
                  </a>{" "}
                  on{" "}
                  <a
                    href={photo.photoURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 dark:text-blue-400 hover:underline"
                  >
                    {photo.platform}
                  </a>
                </p>
                {/* 
                <p className="text-sm font-semibold text-gray-700 dark:text-gray-300">
                  Assessment: {photo.assessment}
                </p>
                */}
              </div>
            </div>
          ))}
        </Masonry>
      </div>
    </>
  );
};

export default ExamplesGallery;
