import React from "react";
import { pricingData } from "./pricingData";
import { useTranslation } from "react-i18next";
import { CreditCard, Clock, Percent, Coins, Calculator } from "lucide-react";

const Pricing: React.FC = () => {
  const { t } = useTranslation("pricing");

  const renderBenefits = () => {
    const benefits = [
      { key: "noSubscription", icon: CreditCard },
      { key: "longerValidity", icon: Clock },
      { key: "volumeDiscount", icon: Percent },
      { key: "simplePricing", icon: Coins },
      { key: "predictableCosts", icon: Calculator },
    ];

    return (
      <div className="max-w-3xl mx-auto mt-12 mb-8">
        <h3 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white text-center">
          {t("benefits.title")}
        </h3>
        <div className="grid md:grid-cols-2 gap-4">
          {benefits.map(({ key, icon: Icon }) => (
            <div
              key={key}
              className="flex items-start space-x-4 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg"
            >
              <div className="flex-shrink-0">
                <Icon className="w-6 h-6 text-purple-600 dark:text-purple-400" />
              </div>
              <div className="flex-1">
                <div className="font-semibold text-gray-900 dark:text-white">
                  {t(`benefits.${key}`)}
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  {t(`benefits.${key}Detail`)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full bg-gray-100 dark:bg-darker text-gray-800 dark:text-gray-300 pt-20">
      <div className="container mx-auto pt-8 pb-12 px-4 max-w-4xl">
        <h2 className="text-3xl md:text-4xl font-bold mb-3 text-center text-gray-900 dark:text-white">
          {t("title")}
        </h2>
        <p className="text-base md:text-lg text-center mb-8 text-gray-600 dark:text-gray-400">
          {t("subtitle")}
        </p>
        <div className="overflow-x-auto">
          <table className="w-full max-w-3xl mx-auto border-collapse text-xs md:text-base">
            <thead>
              <tr className="bg-gray-100 dark:bg-gray-800">
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.package")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.credits")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.price")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.pricePerCredit")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.validity")}
                </th>
                <th className="text-left py-2 md:py-3 px-2 md:px-4 font-semibold border-b border-gray-200 dark:border-gray-700">
                  {t("table.headers.savings")}
                </th>
              </tr>
            </thead>
            <tbody>
              {pricingData.map((plan, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
                >
                  <td className="py-2 md:py-3 px-2 md:px-4 font-medium">
                    {t(`packages.${plan.package}`)}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {plan.credits.toLocaleString()}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {t("currency", { price: plan.price })}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {t("currency", { price: plan.pricePerCredit.toFixed(2) })}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    {t("validity", { days: plan.validity_in_days })}
                  </td>
                  <td className="py-2 md:py-3 px-2 md:px-4">
                    <span
                      className={
                        plan.discount
                          ? "text-green-600 dark:text-green-400"
                          : ""
                      }
                    >
                      {t(`savings.${plan.discount || "none"}`)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {renderBenefits()}
      </div>
    </div>
  );
};

export default Pricing;
