// hooks/useSchema.ts
import { useState, useEffect } from 'react';

export const useSchema = (language: string, type: string = 'website') => {
    const [schema, setSchema] = useState<Object | null>(null);

    useEffect(() => {
        const loadSchema = async () => {
            try {
                const response = await fetch(`/locales/${language}/schema.json`);
                const data = await response.json();

                // If the schema has different types (website, product, etc.), 
                // you can select the appropriate one
                setSchema(data[type] || data);
            } catch (error) {
                console.error('Error loading schema:', error);
                setSchema(null);
            }
        };

        loadSchema();
    }, [language, type]);

    return schema;
};