import React from "react";
import { useEffect } from "react";
import Layout from "../../../components/Documentation/Layout";
import Content from "../../../components/Documentation/Contents/Credit";
import { useAuth } from "../../../contexts/AuthContext/AuthContext";
import { useSecondaryNavbar } from "../../../components/SecondaryNavbarContext";
import SEOHead from "../../../components/SEOHead";
import {
  AuthContent,
  UnauthContent,
} from "../../../components/SecondaryNavbars/ApiKeyAndCredits";
import { useTranslation } from "react-i18next";

const AlphaChannel: React.FC = () => {
  const { isAuthenticated, credits, apiKey, expiryDate } = useAuth();
  const { setContent } = useSecondaryNavbar();
  const { t } = useTranslation("pages/docs/credit");

  useEffect(() => {
    if (isAuthenticated) {
      setContent(
        <AuthContent
          credits={credits}
          apiKey={apiKey}
          expiryDate={expiryDate}
        />
      );
    } else {
      setContent(<UnauthContent />);
    }
    return () => {
      setContent(null);
    };
  }, [setContent, isAuthenticated, credits]);

  return (
    <>
      <SEOHead
        title={t("title")}
        description={t("description")}
        imageUrl="https://withoutbg.com/withoutbg-og.png"
      />
      <Layout>
        <Content />
      </Layout>
    </>
  );
};

export default AlphaChannel;
