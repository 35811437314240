import React from "react";
import { useTranslation } from "react-i18next";
import SEOHead from "../../components/SEOHead";
import BackToTop from "../../components/Resources/BackToTop";

const DatasetCreation = () => {
  const { t } = useTranslation("resources/datasetCreation");

  return (
    <>
      <SEOHead
        title={t("meta.title")}
        description={t("meta.description")}
        imageUrl="https://withoutbg.com/dataset-creation-documentation-og.png"
      />

      <div className="min-h-screen bg-white dark:bg-gray-900 mt-10">
        <main className="max-w-4xl mx-auto px-4 py-12">
          {/* Complex Images Section */}
          <section className="mb-12">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8">
              {t("complexImages.title")}
            </h1>
            <p className="text-lg text-gray-700 dark:text-gray-300 mb-4">
              {t("complexImages.description")}
            </p>
            <p className="text-lg text-gray-700 dark:text-gray-300">
              {t("complexImages.additionalInfo")}
            </p>
          </section>

          {/* Novel Approach Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("novelApproach.title")}
            </h2>
            <p className="text-lg text-gray-700 dark:text-gray-300 mb-8">
              {t("novelApproach.description")}
            </p>

            {/* Steps */}
            <div className="space-y-8">
              {/* Setup Step */}
              <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-3">
                  {t("novelApproach.steps.setup.title")}
                </h3>
                <p className="text-gray-700 dark:text-gray-300">
                  {t("novelApproach.steps.setup.description")}
                </p>
              </div>

              {/* First Shot Step */}
              <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-3">
                  {t("novelApproach.steps.firstShot.title")}
                </h3>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                  {t("novelApproach.steps.firstShot.description")}
                </p>
                <img
                  src={t("novelApproach.steps.firstShot.image.src")}
                  alt={t("novelApproach.steps.firstShot.image.alt")}
                  className="w-full h-auto rounded-lg shadow-lg"
                  loading="lazy"
                />
              </div>

              {/* Second Shot Step */}
              <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-3">
                  {t("novelApproach.steps.secondShot.title")}
                </h3>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                  {t("novelApproach.steps.secondShot.description")}
                </p>
                <img
                  src={t("novelApproach.steps.secondShot.image.src")}
                  alt={t("novelApproach.steps.secondShot.image.alt")}
                  className="w-full h-auto rounded-lg shadow-lg"
                  loading="lazy"
                />
              </div>

              {/* Background Removal Step */}
              <div className="border border-gray-200 dark:border-gray-700 rounded-lg p-6">
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-3">
                  {t("novelApproach.steps.backgroundRemoval.title")}
                </h3>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                  {t("novelApproach.steps.backgroundRemoval.description")}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {t("novelApproach.steps.backgroundRemoval.images", {
                    returnObjects: true,
                  }).map((image, index) => (
                    <img
                      key={index}
                      src={image.src}
                      alt={image.alt}
                      className="w-full h-auto rounded-lg shadow-lg"
                      loading="lazy"
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* Examples Section */}
            <div className="mt-12">
              <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
                {t("novelApproach.examples.title")}
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {t("novelApproach.examples.images", {
                  returnObjects: true,
                }).map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className="w-full h-auto rounded-lg shadow-lg"
                    loading="lazy"
                  />
                ))}
              </div>
            </div>
          </section>

          {/* Benefits Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">
              {t("benefits.title")}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {t("benefits.items", { returnObjects: true }).map(
                (item, index) => (
                  <div
                    key={index}
                    className="p-6 border border-gray-200 dark:border-gray-700 rounded-lg"
                  >
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                      {item.title}
                    </h3>
                    <p className="text-gray-700 dark:text-gray-300">
                      {item.description}
                    </p>
                  </div>
                )
              )}
            </div>
          </section>

          {/* Summary Section */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("summary.title")}
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              {t("summary.description")}
            </p>
            <a
              href={t("summary.datasetLink")}
              className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-200 underline"
            >
              withoutbg100 Image Matting Dataset
            </a>
          </section>
        </main>

        <BackToTop />
      </div>
    </>
  );
};

export default DatasetCreation;
