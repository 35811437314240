import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from "../utils/languageUtils";
import { useSchema } from "../hooks/useSchema";

interface SEOHeadProps {
  title: string;
  description: string;
  imageUrl?: string;
  noindex?: boolean;
  loadFonts?: boolean;
  enableSchema?: boolean;
  schema?: Object;
  type?: string;
}

const SEOHead: React.FC<SEOHeadProps> = ({
  title,
  description,
  imageUrl = "https://withoutbg.com/withoutbg-og.png",
  noindex = false,
  loadFonts = false,
  enableSchema = false,
  schema: customSchema = null,
  type = "website",
}) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentLanguage = i18n.language;
  const baseUrl = "https://withoutbg.com";
  const currentPath = location.pathname;
  const strippedPath = currentPath.replace(/^\/[a-z]{2}\//, "/");

  const loadedSchema = useSchema(currentLanguage, type);
  const finalSchema = customSchema || (enableSchema ? loadedSchema : null);

  const canonicalUrl = `${baseUrl}${
    currentLanguage === DEFAULT_LANGUAGE ? currentPath : strippedPath
  }`;
  const currentUrl = `${baseUrl}${currentPath}`;
  const alternateUrls = SUPPORTED_LANGUAGES.map((lang) => ({
    lang,
    url: `${baseUrl}${
      lang === DEFAULT_LANGUAGE ? strippedPath : `/${lang}${strippedPath}`
    }`,
  }));

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        {/* Essential Meta Tags - These should be first */}
        <html lang={currentLanguage} />
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* Security and Compatibility */}
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="referrer" content="strict-origin-when-cross-origin" />
        <meta httpEquiv="content-language" content={currentLanguage} />

        {/* Theme and Application */}
        <meta name="theme-color" content="#000000" />
        <meta name="msapplication-TileColor" content="#da532c" />

        {/* Canonical and Language Alternates */}
        <link rel="canonical" href={canonicalUrl} />
        {alternateUrls.map(({ lang, url }) => (
          <link key={lang} rel="alternate" hrefLang={lang} href={url} />
        ))}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${baseUrl}${strippedPath}`}
        />

        {/* Open Graph */}
        <meta property="og:site_name" content="WithoutBG" />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={currentUrl} />
        <meta
          property="og:locale"
          content={currentLanguage.replace("-", "_")}
        />
        {SUPPORTED_LANGUAGES.map((lang) => (
          <meta
            key={`og-locale-${lang}`}
            property="og:locale:alternate"
            content={lang.replace("-", "_")}
          />
        ))}

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@withoutbg" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />

        {/* Schema.org */}
        {finalSchema && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              ...finalSchema,
              url: currentUrl,
            })}
          </script>
        )}

        {/* Font Loading - Optimized */}
        {loadFonts && (
          <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin=""
            />
            <link
              href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap"
              rel="stylesheet"
              media="print"
              onLoad={(e: React.SyntheticEvent<HTMLLinkElement>) => {
                e.currentTarget.media = "all";
              }}
            />
          </>
        )}

        {/* Robots Meta */}
        {noindex && <meta name="robots" content="noindex,nofollow" />}
      </Helmet>
    </HelmetProvider>
  );
};

export default SEOHead;
