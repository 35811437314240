import React from "react";
import Editor from "../components/Demo/Editor";
import { useTranslation } from "react-i18next";
import SEOHead from "../components/SEOHead";

const Try: React.FC = () => {
  const { t } = useTranslation("demo"); // 'try' will be the namespace for this page

  return (
    <>
      <SEOHead
        title={t("meta.title", "Remove Background")}
        description={t(
          "meta.description",
          "Free demo: Upload an image to test our background removal API. Instant, high-resolution results. Try now."
        )}
        imageUrl="https://withoutbg.com/withoutbg-og.png"
      />

      <div className="bg-light dark:bg-dark transition-colors duration-200 flex flex-col items-center justify-center min-h-screen">
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">
            {t("heading", "Remove Background")}
          </h2>
          <p className="mb-6 text-gray-600 dark:text-gray-400">
            {t(
              "description",
              "Upload an image and remove the background with a single click."
            )}
          </p>
          <Editor
            showPredefinedImages={true}
            showBottomActionBarAfterProcessing={true}
          />
        </div>
      </div>
    </>
  );
};

export default Try;
