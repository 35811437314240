import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SEOHead from "../../components/SEOHead";
import BackToTop from "../../components/Resources/BackToTop";

const NovaCanvas = () => {
  const { t } = useTranslation("resources/awsNovaCanvas");

  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);

  const questions = [
    { question: "Output Format", content: t("key_questions.items.0.content") },
    { question: "Complex Cases", content: t("key_questions.items.1.content") },
    { question: "Performance", content: t("key_questions.items.2.content") },
    { question: "Comparison", content: t("key_questions.items.3.content") },
  ];

  return (
    <>
      <SEOHead
        title={t("meta.title")}
        description={t("meta.description")}
        imageUrl="https://withoutbg.com/resources/aws-nova/aws-nova-canvas-model-image-background-removal.png"
      />

      <div className="min-h-screen bg-white dark:bg-gray-900">
        <main className="max-w-4xl mx-auto px-4 py-12">
          {/* Main Title */}
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8 mt-10">
            {t("main_title")}
          </h1>

          {/* Introduction */}
          <section className="mb-12">
            <p className="text-lg text-gray-700 dark:text-gray-300">
              {t("intro.content")}
            </p>
          </section>

          {/* Demo Image */}
          <section className="mb-12">
            <div className="rounded-lg overflow-hidden shadow-lg border border-gray-200 dark:border-gray-700">
              <img
                src={t("demo_image.image.url")}
                alt={t("demo_image.image.alt")}
                className="w-full h-auto"
                loading="lazy"
              />
            </div>
          </section>

          {/* Key Questions */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("key_questions.title")}
            </h2>
            <div className="space-y-4">
              {questions.map((item, index) => (
                <div key={index} className="flex gap-2">
                  <div className="font-semibold text-gray-900 dark:text-white min-w-[120px]">
                    {item.question}:
                  </div>
                  <div className="text-gray-700 dark:text-gray-300">
                    {item.content}
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Initial Assessment */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("assessment.title")}
            </h2>
            <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
              {t("assessment.content")}
            </p>
          </section>

          {/* Status */}
          <section className="mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {t("status.title")}
            </h2>
            <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
              {t("status.content")}
            </p>
          </section>
        </main>

        <BackToTop />
      </div>
    </>
  );
};

export default NovaCanvas;
